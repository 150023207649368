import MemberService from "@/services/members";
import { User } from "@/@types/auth";
import { CognitoUserPool, CognitoUser, AuthenticationDetails } from "amazon-cognito-identity-js";
import AuthModule from "@/store/auth";
export default class Cognito {
  static poolData = {
    UserPoolId: process.env.VUE_APP_COGNITO_USERPOOL_ID ?? "",
    ClientId: process.env.VUE_APP_COGNITO_CLIENT_ID ?? "",
  };
  static userPool = new CognitoUserPool(Cognito.poolData);
  static signIn(email: string, password: string): Promise<User> {
    // ログイン認証の対象とするCognito User Poolとユーザを設定する
    const userData = {
      Username: email,
      Pool: this.userPool,
    };
    const cognitoUser = new CognitoUser(userData);
    // 認証用に渡すusernameとpasswordを設定する
    const authenticationDetails = new AuthenticationDetails({
      Username: email,
      Password: password,
    });
    return new Promise((resolve, reject) => {
      // ログイン認証を実行する
      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: async (result) => {
          // ログイン成功時の処理を実装する
          const cognitoId = result.getAccessToken().decodePayload().username;
          if (cognitoId) {
            const userRes = await MemberService.get(cognitoId);
            const user: User = userRes.data.data[0];
            resolve(user);
            // AuthModule.changeMe(user);
          }
          // console.log("Login Success");
          // console.log("Access Token(jwtToken)=" + result.getAccessToken().getJwtToken());
          // console.log("ID Token(jwtToken)=" + result.getIdToken().getJwtToken());
          // console.log("Refresh Token=" + result.getRefreshToken().getToken());
          // console.log("username(congitoの一意ID)=" + result.getAccessToken().decodePayload().username);
        },
        onFailure: (err) => {
          // ログイン失敗時の処理を実装する
          reject(err);
        },
      });
    });
  }
  // 認証ずみかどうか
  public static isAuthenticated(): Promise<CognitoUser> {
    const currentUser = this.userPool.getCurrentUser();
    return new Promise((resolve, reject) => {
      if (currentUser === null) {
        AuthModule.changeMe(null);
        reject(currentUser);
      } else {
        currentUser.getSession((err: any, session: any) => {
          if (err) {
            reject(err);
          } else {
            if (!session.isValid()) {
              reject(session);
            } else {
              resolve(currentUser);
            }
          }
        });
      }
    });
  }
  // ログアウト
  public static signOut() {
    const currentUser = this.userPool.getCurrentUser();
    currentUser?.signOut();
    AuthModule.changeMe(null);
  }

  // パスワード忘れメール送信
  public static forgetPassword(email: string) {
    const userData = { Username: email, Pool: this.userPool };
    const cognitoUser = new CognitoUser(userData);
    return new Promise((resolve, reject) => {
      cognitoUser.forgotPassword({
        onSuccess: (result) => {
          resolve(result);
        },
        onFailure: (err) => {
          reject(err);
        },
      });
    });
  }

  // パスワードリセット
  public static resetPassword(email: string, newPassword: string, code: string) {
    const userData = { Username: email, Pool: this.userPool };
    const cognitoUser = new CognitoUser(userData);
    return new Promise((resolve, reject) => {
      cognitoUser.confirmPassword(code, newPassword, {
        onSuccess: (result) => {
          resolve(result);
        },
        onFailure: (err) => {
          reject(err);
        },
      });
    });
  }

  // パスワード変更
  public static changePassword(oldPassword: string, newPassword: string, callback: any) {
    const currentUser = this.userPool.getCurrentUser();
    currentUser?.getSession((err: any, result: any) => {
      if (result && currentUser) {
        currentUser.changePassword(oldPassword, newPassword, callback);
      } else {
        callback(err, null);
      }
    });
  }
}
